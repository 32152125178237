import { OnlineStore, OnlineStoreStatus, ShopifyOnlineStore } from '@packages/types'
import React, { useMemo } from 'react'

import ECommerceLogo from 'cms/onlineStores/components/ECommerceLogo'
import { trpc } from 'common/hooks/trpc'

import useCommonVariantsColumns, { ColumnDef } from '../common/useCommonVariantsColumns'
import ShopifyVariantConnectionCell from './ShopifyVariantConnectionCell'
import ShopifyVariantsBulkPopover from './ShopifyVariantsBulkPopover'

const useShopifyVariantsColumns = (
  locationId: string,
  variantsCombination: string[],
  notScrolled: boolean,
  fullyScrolled: boolean,
  productId: string
): ColumnDef[] => {
  const { data: onlineStores = [] } = trpc.onlineStore.list.useQuery(undefined, {
    select: (data: OnlineStore[]) => data.filter(store => store.status === OnlineStoreStatus.Installed),
  })

  const baseColumns = useCommonVariantsColumns(
    locationId,
    variantsCombination,
    notScrolled,
    fullyScrolled,
    ShopifyVariantsBulkPopover
  )

  return useMemo((): ColumnDef[] => {
    return [
      ...baseColumns,
      ...onlineStores?.map(
        (onlineStore): ColumnDef => ({
          key: onlineStore.id,
          title: () => (
            <div className="flex items-center space-x-2">
              <ECommerceLogo eCommerce={onlineStore.eCommerce} />
              <span className="whitespace-nowrap overflow-hidden text-ellipsis">{onlineStore.name}</span>
            </div>
          ),
          className: 'w-[220px] min-w-[220px] max-w-[220px] whitespace-nowrap overflow-hidden text-ellipsis pr-4',
          cellClassName: 'max-w-[220px] overflow-hidden whitespace-nowrap text-ellipsis pr-4',
          render: (variant, _formik, _bulkSelection) => {
            return (
              <ShopifyVariantConnectionCell
                variant={variant}
                combination={variantsCombination}
                productId={productId}
                onlineStore={onlineStore as ShopifyOnlineStore}
              />
            )
          },
        })
      ),
    ]
  }, [locationId, variantsCombination, notScrolled, fullyScrolled, onlineStores])
}

export default useShopifyVariantsColumns
