import { useQuery } from '@tanstack/react-query'

import useShopifyProxyService from 'cms/onlineStores/hooks/useShopifyProxyService'

export interface useShopifyVariantConnectionProps {
  onlineStoreId: string
  productId: string
  variantsCombination: string[]
  variantId: string
}

const useShopifyVariantConnection = ({
  onlineStoreId,
  productId,
  variantsCombination,
  variantId,
}: useShopifyVariantConnectionProps) => {
  const shopifyProxyService = useShopifyProxyService()
  const { data, isLoading } = useQuery(
    [...shopifyProxyService.fetchVariants.queryKeys, onlineStoreId, productId, variantsCombination.join(',')],
    () => shopifyProxyService.fetchVariants(onlineStoreId, productId, variantsCombination.join(',')),
    {
      enabled: !!onlineStoreId,
      retry: false,
      retryOnMount: false,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    }
  )

  const shopifyVariant = data?.variants.find(variant => variant.ref?.value === variantId)

  return {
    shopifyProductId: data?.productId,
    shopifyVariant,
    isLoading,
  }
}

export default useShopifyVariantConnection
