import { ClearFiltersButton } from '@packages/sk8/filter'
import { Table } from '@packages/sk8/table'
import classNames from 'classnames'
import React from 'react'
import { TableVirtuoso } from 'react-virtuoso'

import useBulkSelection from 'common/hooks/useBulkSelection'

import CombinationTitle from '../common/CombinationTitle'
import useVariantsFilter from '../common/useVariantsFilters'
import useVariantsTable, { VariantsTableProps } from '../common/useVariantsTable'
import useVariantsTableEmptyPlaceholderRender from '../common/useVariantsTableEmptyPlaceholderRender'
import VariantsTableCard from '../common/VariantsTableCard'
import VariantsTableFilterContainer from '../common/VariantsTableFilterContainer'
import VariantsTabs from '../common/VariantsTabs'
import VariantsTableFilterList from './ShopifyVariantsTableFilters'
import VariantsTableFooter from './ShopifyVariantsTableFooter'
import useVariantsColumns from './useVariantsColumns'

const ShopifyVariantsTable = ({
  formik,
  locationId,
  variantsCombination = [],
  customizerProduct,
  onChangeCombinationClick,
  scrollParentRef,
  productId,
  fetchMore,
  dataTable,
  variantIds,
  isFetching,
}: VariantsTableProps) => {
  const variants = Object.values(formik.values.variants).filter(variant => variantIds.includes(variant.id))

  const {
    StickyHeader,
    StickyHeaderContainer,
    tableProps,
    stickyFilterContainerProps,
    scrollToTopButtonProps,
    outerComponentProps,
    innerComponentProps,
    notScrolled,
    fullyScrolled,
    Scroller,
  } = useVariantsTable({ scrollParentRef, variantCount: variants.length })

  const filters = useVariantsFilter(dataTable)
  const bulkSelection = useBulkSelection(variantIds)
  const columns = useVariantsColumns(locationId, variantsCombination, notScrolled, fullyScrolled, productId)

  const emptyPlaceholderRender = useVariantsTableEmptyPlaceholderRender({
    filters,
    dataTable,
    onChangeCombinationClick,
  })

  return (
    <>
      <CombinationTitle combination={variantsCombination} customizerProduct={customizerProduct} />
      <VariantsTableCard>
        <VariantsTabs formik={formik} variants={variants} dataTable={dataTable} />
        <VariantsTableFilterContainer isScrollAtTop={stickyFilterContainerProps.isScrollAtTop}>
          <VariantsTableFilterList
            formik={formik}
            customizerProduct={customizerProduct}
            filters={filters}
            variantsCombination={variantsCombination}
          />
          <ClearFiltersButton onClick={filters.clearAllFilters} disabled={filters.areFiltersEmpty || formik.dirty} />
        </VariantsTableFilterContainer>
        <StickyHeaderContainer />

        <TableVirtuoso
          {...tableProps}
          useWindowScroll
          customScrollParent={scrollParentRef.current || undefined}
          totalCount={variants.length}
          defaultItemHeight={41}
          overscan={200}
          atBottomThreshold={20}
          endReached={() => {
            fetchMore()
          }}
          components={{
            EmptyPlaceholder: emptyPlaceholderRender,
            Table: Table,
            TableBody: Table.Body,
            TableRow: Table.Row,
            TableHead: StickyHeader,
            Scroller: Scroller,
          }}
          fixedHeaderContent={() => {
            return (
              <>
                <Table.HeaderRow className="bg-white">
                  {columns.map(column => {
                    return (
                      <Table.HeaderCell key={column.key} className={column.className}>
                        <span>{column.title(formik, bulkSelection, customizerProduct, variants)}</span>
                      </Table.HeaderCell>
                    )
                  })}
                </Table.HeaderRow>
                {isFetching && <Table.Loader colSpan={6} className="absolute z-[2] top-[36px] w-full" />}
              </>
            )
          }}
          itemContent={index => {
            const variant = variants[index]

            return (
              <>
                {columns.map(column => {
                  return (
                    <Table.Cell
                      key={column.key}
                      className={classNames(column.cellClassName, 'transition-colors', {
                        'bg-tertiary-green-50': variant.isNewFromPublish,
                      })}
                    >
                      {column.render(variant, formik, bulkSelection, customizerProduct)}
                    </Table.Cell>
                  )
                })}
              </>
            )
          }}
        />

        <VariantsTableFooter
          scrollToTopButtonProps={scrollToTopButtonProps}
          horizontalScrollProps={{
            outerComponentProps,
            innerComponentProps,
          }}
        />
      </VariantsTableCard>
    </>
  )
}

export default ShopifyVariantsTable
