import { Button } from '@packages/sk8/button'
import { Modal } from '@packages/sk8/modal'
import { ToastType, useToast } from '@packages/sk8/toast'
import React from 'react'

import UpdateCount from 'common/components/UpdateCount'
import { trpc } from 'common/hooks/trpc'

import ConnectionModalOnlineStoreSelection from '../common/connection/ConnectionModalOnlineStoreSelection'
import ConnectionModalWarnings from '../common/connection/ConnectionModalWarnings'
import useShopifyOnlineStores from './useShopifyOnlineStores'

interface ShopifyDisconnectModalProps {
  ids: string[]
  handleClose: () => void
  modalProps: Omit<React.ComponentProps<typeof Modal>, 'children'>
  onSuccess: () => void
}

const ShopifyDisconnectModal = ({ ids, onSuccess, handleClose, modalProps }: ShopifyDisconnectModalProps) => {
  const { openToast } = useToast()
  const { onlineStores, connectedOnlineStores, selectedOnlineStores, setSelectedOnlineStores, isLoading } =
    useShopifyOnlineStores()

  const { mutate: bulkDisconnect, isLoading: isDisconnecting } = trpc.variant.bulkDisconnect.useMutation({
    onSuccess: () => {
      onSuccess()
      openToast('Variants were successfully disconnected.', ToastType.success)
    },
    onError: () => {
      openToast('Could not disconnect variant please try again.', ToastType.warning)
    },
  })

  const handleSave = () => {
    return bulkDisconnect({ ids, onlineStoreIds: selectedOnlineStores }, { onSettled: handleClose })
  }

  return (
    <Modal onBackdropClick={handleClose} {...modalProps}>
      <Modal.CloseButton onClick={handleClose} />
      <Modal.Title>Disconnect variants from Shopify store</Modal.Title>
      <Modal.Details>All the selected variants will be disconnected from your Shopify store.</Modal.Details>

      <ConnectionModalWarnings
        onlineStores={onlineStores}
        connectedOnlineStores={connectedOnlineStores}
        isLoading={isLoading}
      />

      <ConnectionModalOnlineStoreSelection
        onlineStores={onlineStores}
        connectedOnlineStores={connectedOnlineStores}
        selectedOnlineStores={selectedOnlineStores}
        setSelectedOnlineStores={setSelectedOnlineStores}
        title="Select which online store you want to disconnect the variants."
      />

      <Modal.Actions className="justify-between items-center">
        <UpdateCount text="variant" actionText="will be disconnected to your online store" count={ids.length} />
        <div className="flex space-x-3">
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            variant="error"
            onClick={handleSave}
            disabled={isLoading || isDisconnecting || selectedOnlineStores.length === 0}
            isLoading={isLoading || isDisconnecting}
          >
            Disconnect
          </Button>
        </div>
      </Modal.Actions>
    </Modal>
  )
}

export default ShopifyDisconnectModal
