import { ShopifyOnlineStore } from '@packages/types'
import React from 'react'

import { VariantFormValue } from '../types'
import useShopifyVariantConnection from './useShopifyVariantConnection'

export interface VariantConnectionCellProps {
  variant: VariantFormValue
  onlineStore: ShopifyOnlineStore
  productId: string
  combination: string[]
}

const ShopifyVariantConnectionCell = ({ variant, productId, combination, onlineStore }: VariantConnectionCellProps) => {
  const { shopifyVariant, shopifyProductId, isLoading } = useShopifyVariantConnection({
    onlineStoreId: onlineStore.id,
    productId,
    variantsCombination: combination,
    variantId: variant.id,
  })

  if (isLoading) return <div className="animate-pulse h-6 bg-neutral-75 rounded w-9/12" />

  if (!shopifyVariant) return <span className="text-neutral-300">Not connected</span>

  const legacyId = shopifyVariant?.id.split('/').pop()

  return (
    <a
      className="text-xs items-center overflow-hidden whitespace-nowrap text-ellipsis w-full block hover:text-primary-500"
      href={`https://${onlineStore.domain}/admin/products/${shopifyProductId}/variants/${legacyId}`}
      target="_blank"
    >
      {shopifyVariant?.title}
    </a>
  )
}

export default ShopifyVariantConnectionCell
